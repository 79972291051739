import APIService from "./APIService";
import { getInstance } from "../auth/auth";

const http = APIService.http;

export default {
  async getPressReleases(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/PressReleases/GetPressReleases",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getPressRelease(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/PressReleases/GetPressRelease",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getPressReleaseSites(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/PressReleases/GetPressReleaseSites",
      filter,
      authHeaders
    );
    return res.data;
  },
  async getSitesForPressRelease(filter) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/PressReleases/GetSitesForPressRelease",
      filter,
      authHeaders
    );
    return res.data;
  },
  async uploadPressRelease(upload) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/PressReleases/UploadPressRelease",
      upload,
      authHeaders
    );
    return res.data;
  },
  async deletePressRelease(upload) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/PressReleases/DeletePressRelease",
      upload,
      authHeaders
    );
    return res.data;
  },
  async updatePressRelease(upload) {
    let auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post(
      "api/PressReleases/UpdatePressRelease",
      upload,
      authHeaders
    );
    return res.data;
  },
  async checkout(order) {
    const auth = getInstance();
    let authHeaders = await auth.apiAuthConfig();
    let res = await http.post("api/PressReleases/CheckOut", order, authHeaders);
    return res.data;
  },
};
