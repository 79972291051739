var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"TinyMce","name":"TinyMce"}},[_c('editor',{attrs:{"api-key":"qmniwxzcl26koe4378x8ncocdyo8l36obo249oeyllqc2gkx","init":{
      height: 500,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount' ],
      toolbar: _vm.toolbarValues,
    },"id":"localtinymce"},model:{value:(_vm.editorValue),callback:function ($$v) {_vm.editorValue=$$v},expression:"editorValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }