<template>
  <div class="card card-md mb-3">
    <div
      class="card-status-top bg-danger"
      v-if="form_has_errors || error"
    ></div>
    <div
      v-if="submittingPayment && !success"
      class="progress progress-sm card-progress"
    >
      <div
        class="progress-bar progress-bar-indeterminate"
        role="progressbar"
      ></div>
    </div>
    <div class="card-status-top bg-success" v-if="success"></div>

    <div class="card-body">
      <b-alert variant="danger" show v-if="error">
        There was an error submitting your payment. A MediaBrains representative
        will reach out to you to complete your upgrade shortly.
      </b-alert>

      <form
        v-if="!submittingPayment && !success"
        id="cc-form"
        @submit.prevent="checkout()"
      >
        <div class="mb-3">
          <label class="form-label" for="number">Card number</label>
          <input
            :class="['form-control', cardErrors.cardNumber ? 'is-invalid' : '']"
            ref="cardNumber"
            id="number"
            v-model="cardNumber"
            @blur="validate('number')"
          />
          <div class="invalid-feedback">
            <small>Please enter a valid card number</small>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-4">
            <label class="form-label" for="expiration">Expiration</label>
            <input
              :class="[
                'form-control',
                cardErrors.cardExpiry ? 'is-invalid' : '',
              ]"
              placeholder="MM/YY"
              ref="expiration"
              id="expiration"
              v-model="cardExpiry"
              v-cardformat:formatCardExpiry
              @blur="validate('expiry')"
            />
            <div class="invalid-feedback">
              <small>Your expiration date must be in the future</small>
            </div>
          </div>
          <div class="col-md-4">
            <label class="form-label" for="cvv">CVV</label>
            <input
              :class="['form-control', cardErrors.cardCvc ? 'is-invalid' : '']"
              ref="cvv"
              v-model="cardCvc"
              id="cvv"
              v-cardformat:formatCardCVC
              @blur="validate('cvv')"
            />
            <div class="invalid-feedback">
              <small>Please enter a valid CVV</small>
            </div>
          </div>
          <div class="col-md-4">
            <label class="form-label" for="cc-zip">Billing Zipcode</label>
            <input
              type="text"
              class="form-control"
              id="cc-zip"
              ref="zip"
              v-model="cardPostal"
            />
            <div class="invalid-feedback">
              <small>Billing zipcode must be a US/Canada valid zipcode</small>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label" for="cc-name">Name on card</label>
          <input
            type="text"
            :class="['form-control', cardErrors.name ? 'is-invalid' : '']"
            id="cc-name"
            ref="name"
            v-model="name"
            required
            @blur="validate('name')"
          />
          <div class="invalid-feedback">
            <small>Name on card is required</small>
          </div>
        </div>

        <div class="text-center mt-2">
          <a href="#" @click.prevent="purchase()" class="btn btn-green w-100">
            {{ this.label }}
          </a>
        </div>
      </form>
      <div v-else-if="success">
        <h3>Congratulations!</h3>
        <p>
          Your upgrade has been processed successfully. Our customer service
          team will be reaching out shortly to finalize your featured listing.
        </p>
      </div>
      <div v-else>
        <h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-lock"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <rect x="5" y="11" width="14" height="10" rx="2"></rect>
            <circle cx="12" cy="16" r="1"></circle>
            <path d="M8 11v-4a4 4 0 0 1 8 0v4"></path>
          </svg>
          Submitting payment information...
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutForm",
  props: {
    submittingPayment: Boolean,
    success: Boolean,
    error: Boolean,
    label: { default: "Purchase Upgrade" },
    price: Number,
  },
  data: () => {
    return {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cardPostal: null,
      cardErrors: {},
      // declaring card-brand in data{} enables card brand name/classes.
      cardBrand: null,
      name: null,
    };
  },
  computed: {
    form_has_errors() {
      return Object.values(this.cardErrors).some((i) => i);
    },
  },
  methods: {
    validate(item) {
      // init
      //this.cardErrors = {};

      // validate card number
      if (item === "all" || item === "number") {
        if (!this.$cardFormat.validateCardNumber(this.cardNumber)) {
          this.$set(this.cardErrors, "cardNumber", true);
        } else {
          this.$set(this.cardErrors, "cardNumber", false);
        }
      }

      // validate card expiry
      if (item === "all" || item === "expiry") {
        if (!this.$cardFormat.validateCardExpiry(this.cardExpiry)) {
          this.$set(this.cardErrors, "cardExpiry", true);
        } else {
          this.$set(this.cardErrors, "cardExpiry", false);
        }
      }

      // validate card CVC
      if (item === "all" || item === "cvv") {
        if (!this.$cardFormat.validateCardCVC(this.cardCvc)) {
          this.$set(this.cardErrors, "cardCvc", true);
        } else {
          this.$set(this.cardErrors, "cardCvc", false);
        }
      }

      if (item === "all" || item === "name") {
        if (this.name == "" || this.name == null) {
          this.$set(this.cardErrors, "name", true);
        } else {
          this.$set(this.cardErrors, "name", false);
        }
      }
    },

    purchase() {
      this.validate("all");
      if (this.form_has_errors) {
        return false;
      }
      console.log("no errors");
      this.$emit("update:checkout-card", {
        number: this.cardNumber,
        expiration: this.cardExpiry,
        cvv: this.cardCvc,
        zip: this.cardPostal,
        name: this.name,
        authorizedAmountCents: this.price,
      });
    },
  },
};
</script>
