<template>
  <div id="TinyMce" name="TinyMce">
    <editor
      api-key="qmniwxzcl26koe4378x8ncocdyo8l36obo249oeyllqc2gkx"
      :init="{
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar: toolbarValues,
      }"
      v-model="editorValue"
      id="localtinymce"
    />
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "TinyMce",
  components: {
    editor: Editor,
  },
  data: () => {
    return {
      editorValue: null,
    };
  },
  props: {
    value: { default: "" },
    toolbarValues: {
      default:
        "undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help",
    },
  },
  watch: {
    value() {
      this.editorValue = this.value;
      this.$emit("update:selected", this.value);
    },
    editorValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    setEditorValue(newValue) {
      this.editorValue = newValue;
    },
  },
};
</script>
